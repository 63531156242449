import Drawer from '@/components/drawers'
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"
import Loading from '@/components/widgets/Loading.vue'

export default {
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    id: {
      type: String
    }
  },
  components: {
    Loading,
    Drawer
  },
  mixins: [validationMixin],
  validations: {
    form: {
      amount: { required }
    },
  },
  data() {
    return {
      loading: false,
      drawerOpened: false,
      items: {},
      form: {
        uuid: null,
        amount: null
      }
    }
  },
  watch: {
    'opened'(n, o) {
      if (n) {
        this.showFileInput = true
        this.drawerOpened = n
      }
    }
  },
  mounted() {
    this.onInitData()
  },
  methods: {
    resetForm () {
      this.form.uuid = null
      this.form.amount = null

      this.$nextTick(() => {
        this.$v.$reset();
      })
    },
    onCloseDrawer(event) {
      this.drawerOpened = false
      this.resetForm()
      this.$emit('close-event', { 
        status: true
      })
    },
    async onInitData () {
        this.items.doc_no = 'INV-2022165465'
        this.items.doc_date = new Date()
        this.items.branch = 'สาขา 1'
        this.items.location = '330 ถ.เชียงใหม่-ลำปาง ต.ป่าตัน อ.เมือง รหัสไปรษณีย์ 50300'
        this.items.status = 'hold on'
        this.items.customer = 'ทำของดี'
        this.items.phone_number = '0827817908'
        this.items.address = '37/2 ถนนสุทธิสารวินิจฉัย แขวงสามเสนนอก เขตห้วยขวาง กทม. 10320'
    },
    async onSubmitForm() {
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      this.loading = true
      const formData = new FormData()
      formData.append('uuid', this.form.code)
      formData.append('amount', this.form.name)

      setTimeout(() => {
        this.loading = false    
        this.resetForm()
        this.$swal({
          width: "29rem",
          padding: '1.825em',
          html: '<div class="d-flex flex-row align-items-center">' +
            '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
            '<div class="d-flex flex-column">'+
            '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
            `<span class="text-base text-left text-gray-500">บันทึกข้อมูลเรียบร้อยแล้ว</span>`+
            '</div>'+
          '</div>',
            allowOutsideClick: false,
            focusConfirm: false,
            customClass: {
              popup: 'swal2-custom-rounded',
              closeButton: 'text-3xl swal2-custom-close',
              actions: 'justify-content-end',
              contant: 'd-flex flex-row justify-content-around px-2',
              title: 'd-none',
              confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
              cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
            },
            showCancelButton: false,
            confirmButtonText: "ตกลง",
            cancelButtonText: "ยกเลิก",
            showCloseButton: true,
        }).then(result => {
          this.onCloseDrawer()
        })
      }, 500)
    }
  }
}
